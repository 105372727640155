<template>
    <b-row class="justify-content-center">
        <b-col md="10">
            <b-card>
                <b-form v-on:submit.prevent>
                    <b-row>
                        <b-col cols="4">
                            <b-form-group
                            label="Employee"
                            label-for="employee"
                            >  
                                <v-select
                                id="employee"
                                v-model="form.selected"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                label="text"
                                :options="form.options"
                                />
                                <!-- Selected: <strong>{{ form.selected }}</strong> -->
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group
                            label="Valid At"
                            label-for="valid_at"
                            >  
                                <flat-pickr
                                id="valid_at"
                                v-model="form.valid_at"
                                class="form-control"
                                :config="{ dateFormat: 'd-m-Y'}"
                                required
                                />
                                <!-- Selected: <strong>{{ form.valid_at }}</strong> -->
                            </b-form-group>
                        </b-col>
                        <b-col cols="4">
                            <b-form-group
                            label="Total Quota"
                            label-for="total"
                            >
                            <b-form-input
                                id="total"
                                placeholder="Total Quota"
                                v-model="form.total"
                                type="number"
                                required
                            />
                            </b-form-group>
                        </b-col>

                        <!-- submit and reset -->
                        <b-col md="12" class="mt-2">
                            <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            type="submit"
                            variant="primary"
                            class="mr-1"
                            @click="addPermitQuota()"
                            >
                            Submit
                            </b-button>
                            <b-button
                            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                            type="reset"
                            variant="outline-secondary"
                            >
                            Reset
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </b-card>
        </b-col>
    </b-row>
</template>

<script>
import {
    BFormSelect, BListGroupItem, BAvatar, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Prism from 'vue-prism-component'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

export default {
    components: {
        ToastificationContent,
        vSelect,
        flatPickr,
        BFormSelect,
        BListGroupItem,
        BAvatar,
        BCard,
        BCardText,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        Prism
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            form: {
                selected: null,
                options: [],
                valid_at: null,
                total: null
            },
            errors: '',
            errMessage: ''
        }
    },
    created() {
        this.getEmployee()
    },
    methods: {
        getEmployee() {
            this.$http.get('employees')
            .then(response => { 
                // console.log(response.data.data)
                this.form.options.push({value: null, text: 'Optional'})
                
                var datas = response.data.data
                var i
                for(i=0; i<datas.length; i++) {
                    var $data = {
                        value: datas[i].id,
                        text: datas[i].name
                    }
                    this.form.options.push($data)
                }
                // console.log(this.form.options)
            });
        },
        addPermitQuota() {
            this.$http
                .post("permit-quota/add", {
                    "user_id": this.form.selected.value,
                    "valid_at": this.form.valid_at,
                    "total": this.form.total
                })
                .then((response) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Notification',
                            icon: 'BellIcon',
                            text: 'Success add Permit Quota',
                            variant: 'success',
                        },
                    })
                    location.href = "/permit-quota"
                    // console.log(response.data.data)
                }).catch((errors) => {
                    this.errMessage = errors.response.data.message
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Notification',
                            icon: 'BellIcon',
                            text: this.errMessage,
                            variant: 'warning',
                        },
                    })
                    console.log(errors.response)
                });
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>